.data-grid-height-1 {
    height: 880px;
}
.form-group-item {
    font-weight: 400;
    color: rgb(115, 82, 199);
    margin: 0 5%;
}
.form-group-item-margin {
    margin: 0 5%;
}
.dx-field-item-label {
    text-align: right;
}

table,
th,
td {
    border: 1px solid#D3D3D3;
}

th {
    background-color: #ffffff;
}

tr:nth-child(odd) {
    background-color: #f8f8f8;
}

tr:nth-child(even) {
    background-color: #ffffff;
}
.dx-fileuploader-button {
    margin-top: -4%;
    margin-left: -2%;
}
.dx-fileuploader-files-container {
    margin-top: -40px !important;
    padding-top: 22px !important;
}
.dx-invalid-message-content {
    transform: translate(0px, 44px) !important;
}
.imageStyling {
    margin-left: 10%;
}

.dx-item-content.dx-list-item-content {
    background-color: #ffffff;
}

.dx-item-content.dx-list-item-content:hover {
    background-color: #f5f7fa;
}

.css-qi5lxn-MuiGrid-root {
    box-sizing: border-box;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: 217px;
    margin-top: -60px;
}
@media (max-width: 600px) {
    .dx-field-item-label {
        text-align: left;
    }
}
@media screen and (min-width: 300px) {
    .grid-filter-parent {
        text-align: center;
        justify-content: center;
    }
    .grid-filter-Button {
        margin-bottom: 10px;
    }
}
@media screen and (min-width: 1024px) {
    .grid-filter-parent {
        text-align: left;
        justify-content: left;
    }
}
