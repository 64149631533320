.image-container {
    text-align: left;
}
.image-preview {
    max-width: 100%;
    max-height: 150px; /* Adjust the maximum height as needed */
    margin: 10px 0;
    margin-left: 34%;
}
#tabListGrid {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    gap: 10px !important;
}
